import type React from 'react'
import { useRouter } from 'next/router'

import { useOnCountryLanguageClick } from '@cms/components/CountryLanguageSelector/useOnCountryLanguageClick'
import { WebLink } from '@knauf-group/ct-designs/components/core/WebLink'
import type { ReferenceWeb, Styles } from '@knauf-group/ct-designs/utils/types'
import { openWindow } from '@knauf-group/ct-designs/utils/utils'
import { useReferenceWithOnClick } from '@knauf-group/ct-shared-nextjs/web/hooks'
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'

type CountryLanguageLinkProps = {
  link: ReferenceWeb
  locale: string
  dataCy?: string
}

const CountryLanguageLink: React.FC<CountryLanguageLinkProps> = ({ link, locale, dataCy }) => {
  const theme = useTheme()
  const onCountryLanguageClick = useOnCountryLanguageClick()
  const router = useRouter()
  const { getReferenceWithOnClick } = useReferenceWithOnClick()

  const referenceWithOnClick = getReferenceWithOnClick({
    reference: link,
    type: 'link',
    eventPayload: {
      cms_component: 'link',
      action: link.label,
    },
  })
  const styles: Styles = {
    link: {
      minHeight: '38px',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
      '& a': {
        py: { xs: theme.spacing(0.5), sm: theme.spacing(1) },
        paddingLeft: '16px',
        color: theme.palette.text.primary,
        width: '100%',
      },
    },
  }

  return (
    <Box sx={styles.link}>
      <WebLink
        variant="body3dense"
        reference={referenceWithOnClick}
        component="a"
        target="_self"
        isStyledLink={false}
        data-cy={dataCy || `language-selector-link-${locale}`}
        onClick={(e) => {
          onCountryLanguageClick(e, locale)

          if (link.external) {
            openWindow(link.href, '_self') // force same tab
          } else {
            router.push(link.href, link.href, { locale })
          }
        }}
      >
        {link.label}
      </WebLink>
    </Box>
  )
}

export default CountryLanguageLink
