import type { Regions } from './CountryLanguage.types'

export const filterCountries = (searchTerm: string, regions: Regions): Regions => {
  if (!searchTerm) {
    return regions
  }

  if (searchTerm.length < 2) {
    return regions
  }

  const regionsFiltered = JSON.parse(JSON.stringify(regions))

  Object.keys(regions).forEach((region) => {
    regionsFiltered[region] = regions[region].filter((item) =>
      item.label.toLowerCase().includes(searchTerm.toLowerCase()),
    )
  })

  return regionsFiltered
}

export const isEmptyAfterFilter = (countries) =>
  (Array.isArray(countries) && countries.flat(Infinity).length === 0) || !countries
