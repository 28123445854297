import type { Styles } from '@knauf-group/ct-designs/utils/types'
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'

import type { CountryLanguageSelectorProps } from './CountryLanguage.types'
import { CountryLanguageRegion } from './CountryLanguageRegion'

const CountryLanguageDesktop = ({ regions, dataCy }: CountryLanguageSelectorProps) => {
  const theme = useTheme()
  const countries = Object.values(regions)
  const continents = Object.keys(regions)

  const styles: Styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      gap: {
        sm: theme.spacing(4),
      },
      '& .MuiDivider-root:last-child': {
        display: 'none',
      },
    },
  }

  return (
    <Box data-cy={`${dataCy}-desktop`} sx={styles.container}>
      {countries.map((innerCountries, index) => (
        <CountryLanguageRegion
          data-cy={`${continents[index]}-link`}
          countries={innerCountries}
          continent={continents[index]}
          key={continents[index]}
        />
      ))}
    </Box>
  )
}

export default CountryLanguageDesktop
