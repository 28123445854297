
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/country-language-selector",
      function () {
        return require("private-next-pages/country-language-selector.tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/country-language-selector"])
      });
    }
  