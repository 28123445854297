import type { WindowWithOneTrust } from '@cms/components/OneTrustCookieManager'
import { NEXT_LOCALE_COOKIE } from '@cms/middleware/constants'
import { setCookie } from 'cookies-next'

export function useOnCountryLanguageClick() {
  return (e: React.MouseEvent, locale: string) => {
    const windowWithOneTrust: WindowWithOneTrust = window

    // prevent default link behavior
    e.preventDefault()

    // set locale pref cookie for next.js
    setCookie(NEXT_LOCALE_COOKIE, locale)

    // change language for OneTrust
    windowWithOneTrust?.OneTrust?.changeLanguage(locale)
  }
}
