import type React from 'react'
import router from 'next/router'
import { useTranslation } from 'next-i18next'

import { useOnCountryLanguageClick } from '@cms/components/CountryLanguageSelector/useOnCountryLanguageClick'
import type { Styles } from '@knauf-group/ct-designs/utils/types'
import { Logger } from '@lib/logger'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import { useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

import type { CountryLanguageRegionProps } from './CountryLanguage.types'
import CountryLanguageLink from './CountryLanguageLink'
import { isEmptyAfterFilter } from './utils'

export const CountryLanguageRegion: React.FC<CountryLanguageRegionProps> = ({
  dataCy = 'contry-area',
  countries,
  continent,
}) => {
  const { t } = useTranslation('cms', { keyPrefix: 'region' })
  const theme = useTheme()
  const onCountryLanguageClick = useOnCountryLanguageClick()

  if (!countries) {
    return null
  }

  const sliceCountries = (countriesToSlice) => {
    try {
      const length = countriesToSlice?.length || 0
      const perChunk = Math.floor(length / 4)
      let chunkLeftovers = length % 4
      let rowCounter = 1
      let columnIndex = 0

      return (
        countriesToSlice?.reduce(
          (acc, country) => {
            acc[columnIndex].push(country)

            if (rowCounter === perChunk + (chunkLeftovers > 0 ? 1 : 0)) {
              rowCounter = 1
              columnIndex += 1
              if (chunkLeftovers > 0) chunkLeftovers -= 1
            } else {
              rowCounter += 1
            }

            return acc
          },
          [[], [], [], []],
        ) || []
      )
    } catch (exceptionVar) {
      Logger.error(
        'Something went wrong while remapping countries on CountryLanguageRegion component.',
        { exceptionVar },
      )
    }
  }

  const styles: Styles = {
    countryWrapper: {
      display: 'flex',
      flexDirection: 'column',
    },
    mainHeadline: {
      py: { xs: theme.spacing(4), lg: theme.spacing(6) },
      textAlign: 'center',
      '&.MuiTypography-root': {
        marginBottom: 0,
      },
    },
    regionHeadline: {
      padding: '4px 16px',
    },
    resetSpacing: {
      '&.MuiGrid-item': {
        py: 0,
        my: 0,
      },
      '&.MuiGrid-container': {
        py: 0,
        my: 0,
      },
    },
    listItemContainer: {
      alignItems: 'baseline',
      cursor: 'pointer',
      '&.MuiListItem-root:hover': {
        background: theme.palette.action.hover,
      },
      '& .MuiLink-root': {
        color: theme.palette.text.primary,
      },
    },
  }

  const countriesSliced = sliceCountries(countries)

  return (
    !isEmptyAfterFilter(countriesSliced) && (
      <>
        <Box sx={styles.countryWrapper} data-cy={dataCy}>
          <Typography variant="h6" component="h2" sx={styles.regionHeadline}>
            {t(continent)}
          </Typography>
          <Grid container sx={styles.resetSpacing} columnSpacing={3}>
            {countriesSliced?.map((countryItem, index) => (
              <Grid item xs={12} sm={3} key={index as React.Key} sx={styles.resetSpacing}>
                <List disablePadding sx={{ border: 0 }}>
                  {countryItem?.map((country, indexItem) => (
                    <ListItem
                      key={`${indexItem}_${country.label}` as React.Key}
                      disablePadding
                      sx={styles.listItemContainer}
                      onClick={(e) => {
                        onCountryLanguageClick(e, country.locale)

                        router.push(country.href, country.href, {
                          locale: country.locale,
                        })
                      }}
                    >
                      <CountryLanguageLink
                        link={{ label: country.label, href: country.href }}
                        locale={country.locale}
                      />
                    </ListItem>
                  ))}
                </List>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Divider />
      </>
    )
  )
}

export default CountryLanguageRegion
