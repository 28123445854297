import Box from '@mui/material/Box'

import type { CountryLanguageSelectorProps } from './CountryLanguage.types'
import CountryLanguageAccordionEntry from './CountryLanguageAccordionEntry'

const CountryLanguageAccordion: React.FC<CountryLanguageSelectorProps> = ({
  dataCy = 'country-language',
  regions,
}) => {
  const countries = Object.values(regions)
  const continents = Object.keys(regions)

  return (
    <Box data-cy={`${dataCy}-accordion`}>
      {countries.map((innerCountries, index) => (
        <CountryLanguageAccordionEntry
          data-cy={`${continents[index]}-link`}
          countries={innerCountries}
          continent={continents[index]}
          key={continents[index]}
        />
      ))}
    </Box>
  )
}

export default CountryLanguageAccordion
